import React, { useState } from "react";
import { Collapse, Input, Button } from "reactstrap";
import { APPLY_COUPON } from "../graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import { Alert, Badge } from "reactstrap";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const inputStyle = {
  fontSize: 25,
  fontWeight: "bold",
  border: "none",
  borderBottom: "1px solid #eee",
};

const DiscountModule = ({ label, total, onRemove, onApply }) => {
  const [open, setOpen] = useState(false);
  const [applied, setApplied] = useState("");
  const [code, setCode] = useState("");
  const [applyCoupon] = useMutation(APPLY_COUPON);

  const toggle = () => {
    setOpen(!open);
  };

  const onChange = ({ target: { value: code } }) => {
    setCode(code);
  };

  const apply = async () => {
    try {
      let {
        data: { coupon },
      } = await applyCoupon({ variables: { code } });

      let d = Number(coupon.discount);
      let value = d;
      let title = "";
      if (coupon.type === "Percentage") {
        value = (d * total) / 100;
        if (value < 0) {
          return (value = 0);
        }
        title = "You got " + d + "% ";
      } else {
        title = "You got €" + d.toFixed(2);
      }

      title += " Discount";

      if (onApply) onApply({ value });
      setApplied(title);
      setOpen(false);
      setCode("");
    } catch (error) {
      alert("Invalid Coupon");
      console.log("Error while apping coupon : ", error.message);
    }
  };

  const remove = () => {
    setApplied("");
    if (onRemove) onRemove();
  };

  return (
    <div
      style={{
        padding: 10,
        border: "1px solid #d4d4d4",
      }}
    >
      {applied ? (
        <Alert color="success">
          {applied}
          <div
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
            }}
          >
            <Badge color="danger" onClick={remove}>
              Remove
            </Badge>
          </div>
        </Alert>
      ) : (
        <>
          {!open && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={toggle}
            >
              <Button color="link">{label}</Button>
              <LocalOfferIcon />
            </div>
          )}

          <Collapse isOpen={open}>
            <br />
            <Input
              style={inputStyle}
              value={code}
              placeholder="Enter Code"
              onChange={onChange}
            />
            <br />
            <div style={{ textAlign: "right" }}>
              <Button onClick={toggle} color="link">
                Cancel
              </Button>
              &nbsp;
              <Button color="info" onClick={apply}>
                Apply
              </Button>
            </div>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default DiscountModule;
