import React, { useEffect, useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import Moment from "react-moment";
import jwtDecode from "jwt-decode";
import "./index.css";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_RIDE } from "../graphql/mutations";
import { toast } from "react-toastify";
import { isBrowser } from "./../../../utils/general";
import BookingHeader from "./bookingHeader";
import { LanguageContext } from "../../../lang";
import ApplyPromo from "./apply-promocode";
import moment from "moment";
var localization = require("moment/locale/nl");

const ConfirmBooking = ({ rideInfo = {}, onClose }) => {
	const {
		translations: { confirmBooking },
	} = useContext(LanguageContext);
	const {
		reviewBookingText,
		outwardText,
		numOfTravelersText,
		contactDetailsText,
		totalText,
		btnText,
		paymentTypeText,
		applyCoupon,
	} = confirmBooking;

	const { step1 = {}, step2 = {}, step3 = {}, step4 = {} } = rideInfo;

	const [createRide, { loading }] = useMutation(CREATE_RIDE);
	let decodedJwtTokenId = "";
	const original_amount = step2.estimateFair ? step2.estimateFair.netFare : 0;
	const [total, setTotal] = useState(original_amount);
	const [discount, setDiscount] = useState();

	const reCalculate = (discount) => {
		let t = step2.estimateFair ? step2.estimateFair.netFare : 0;
		if (discount) {
			let { value } = discount;
			t = t - value;
		}
		if (t < 0) t = 0;
		setDiscount(discount);
		setTotal(t);
	};

	useEffect(() => {
		let token = isBrowser()
			? JSON.parse(localStorage.getItem("token"))
			: {};
		let sessionToken = isBrowser()
			? JSON.parse(sessionStorage.getItem("token"))
			: {};
		if (token || sessionToken) {
			const { _id } = jwtDecode(token ? token : sessionToken);
			decodedJwtTokenId = _id;
		}
	}, []);

	const confrimOrder = async () => {
		const { noOfPassengers, ...rest } = step1;
		const {
			custFullName,
			custPhone,
			paymentType,
			custNotes,
			id: taxiId,
			totalFare,
			netFare,
			distanceKM: distance,
			priceKm,
			custEmail,
			estimatedTimeInMins,
			driverEarn,
		} = {
			...step2.taxiData,
			...step2,
			...step2.estimateFair,
			...step4,
			...step3,
		};

		const data = {
			...rest,
			paymentType,
			custId: decodedJwtTokenId ? decodedJwtTokenId : null,
			custFullName,
			custPhone,
			custEmail,
			note: custNotes,
			taxiTypeId: taxiId,
			netFare: total,
			estimatedTimeInMins,
			totalFare,
			distance,
			driverEarn,
			currentTaxiRate: String(priceKm),
			numberOfPassenger: parseInt(noOfPassengers),
			billType: "fixed_bill",
		};
		console.log("FINAL DATA++++++++++++", data);
		try {
			const res = await createRide({
				variables: {
					data,
				},
			});
			localStorage.setItem("XXXX", res.data.createRide.id);
			sessionStorage.setItem(
				"@paymentConfirmation",
				res.data.createRide.id
			);
			navigate("/ride-waiting", {
				state: { id: res.data.createRide.id },
			});
			// toast.success("Ride Created Successfully", {
			//   autoClose: 1200,
			// });
		} catch (error) {
			console.log("error: ", error);
			toast.error("Error While Creating Ride", {
				autoClose: 1200,
			});
		}
	};

	return (
		<section id="book-form">
			<div className="container">
				<div className="row d-flex justify-content-center">
					<div className="col-12 ts-ride-confirmation-wrapper p-0">
						<div
							style={{
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								position: "absolute",
								left: 0,
								top: 5,
							}}
							onClick={onClose}
						>
							<i className="fa fa-arrow-left ts-form-back-arrow"></i>
							{/* <span>Back</span> */}
						</div>
						<h3 className="ts-confirmation-h3 text-center pt-3 pb-4">
							{reviewBookingText}
						</h3>

						<div
							className="ts-ride-detail p-3"
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<h4 className="ts-confirmation-h4">
								{outwardText}
							</h4>
							<p className="ts-confirmation-price num-font-family">
								€
								{step2.estimateFair
									? step2.estimateFair.netFare
									: 0}
							</p>
						</div>

						<div className="ts-pick-drop-location m-3">
							<div className="row ts-pick-location p-3">
								<div className="col-10">
									<ul className="p-0 ts-pick-location-list">
										<li>
											<img
												className="img-fluid"
												src={require("../../../images/icons/map-pin-1.png")}
											/>
										</li>
										{/* <li className="ml-4 ts-confirmation-pick-time">21:25</li> */}
										<li className="ml-4 ts-confirmation-pick-location">
											{/* <span>Jan de Rijstraat 11</span> */}
											{/* <br /> */}
											{/* 3623ED Best, Netherland */}
											<span>{step1.pickupLoc}</span>
										</li>
									</ul>
								</div>
								{/* <div className="col-2">
                  <i className="fa fa-pencil ts-edit-confirmation-details"></i>
                </div> */}
							</div>

							<div className="row ts-drop-location p-3">
								<div className="col-10">
									<ul className="p-0 ts-drop-location-list">
										<li>
											<img
												className="img-fluid"
												src={require("../../../images/icons/map-pin-2.png")}
											/>
										</li>
										{/* <li className="ml-4 ts-confirmation-drop-time">21:25</li> */}
										<li className="ml-4 ts-confirmation-drop-location">
											{/* <span>Jan de Rijstraat 11</span>
                      <br />
                      3623ED Best, Netherland */}
											<span>{step1.destLoc}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row ts-travelers-wrapper m-3 pt-3">
							<div className="col-10">
								<h5 className="ts-confirmation-h5">
									{numOfTravelersText}
								</h5>
								<p className="ts-confirmation-text num-font">
									{step2.noOfPassengers}
								</p>
							</div>
						</div>
						{/* <div className="row ts-travelers-wrapper m-3 pt-3">
              <div className="col-10">
                <h5 className="ts-confirmation-h5">{paymentTypeText}</h5>
                <p className="ts-confirmation-text num-font">
                  {step4.paymentType}
                </p>
              </div>
            </div> */}

						<div className="row ts-contact-details-wrapper m-3 pt-3">
							<div className="col-10">
								<h5 className="ts-confirmation-h5">
									{contactDetailsText}
								</h5>
								<p className="ts-confirmation-text">
									{step4.custFullName}
								</p>
								<p className="ts-confirmation-text">
									{step4.email}
								</p>
								<p className="ts-confirmation-text">
									{step4.custPhone}
								</p>
							</div>
						</div>

						<ApplyPromo
							onApply={reCalculate}
							onRemove={reCalculate}
							total={original_amount}
							label={applyCoupon}
						/>

						<div className="ts-confirmation-total-wrapper flex-space-between m-1">
							<div>
								<p className="ts-total-title p-3">
									{totalText}
								</p>
							</div>
							<div>
								<div>
									{discount && (
										<div
											className="ts-total-price p-3 num-font-family"
											style={{
												textDecoration: "line-through",
												color: "#dc3545",
												fontSize: 22,
											}}
										>
											€{original_amount}
										</div>
									)}
								</div>
								<div>
									<div className="ts-total-price p-3 num-font-family">
										€{total.toFixed(2)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row d-flex justify-content-center mt-5 mb-3">
					<div className="col-12 ts-form-btn-wrapper">
						<div className="row">
							{/* <div
                className="col-6 p-0"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={onClose}
              >
                <i className="fa fa-arrow-left ts-form-back-arrow"></i>
                <span>Back to Personal Info</span>
              </div> */}
							<div className="col-12">
								<button
									type="button"
									className="ts-form-next-link py-2 text-white border-0 m-0 rounded"
									onClick={confrimOrder}
									disabled={loading}
									style={{
										width: "-webkit-fill-available",
										fontSize: 18,
									}}
								>
									{btnText}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ConfirmBooking;
