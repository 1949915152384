import gql from "graphql-tag";

export const CREATE_RIDE = gql`
  mutation bookRide($data: CreateRideInput!) {
    createRide(data: $data) {
      id
    }
  }
`;
export const APPLY_COUPON = gql`
  mutation ApplyCoupon($code: String!) {
    coupon(code: $code) {
      id
      discount
      type
      upto
      title
    }
  }
`;
export default {
  CREATE_RIDE,
};
