import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LanguageContext } from '../../../lang'

const BookingHeader = ({ currentStep, price }) => {
  const {
    translations: { bookingMenu }
  } = useContext(LanguageContext)
  const { locationText, bookingDetail, waitingText, doneText } = bookingMenu
  return (
    <div className='container'>
      <div className='row d-flex justify-content-center'>
        <div className='col-12 steps-wrapper'>
          <div className='steps-form'>
            <div className='steps-row-2 setup-panel-2 d-flex justify-content-between'>
              <div className='steps-step-2 '>
                <a
                  href='#step-1'
                  className='btn btn-circle-2 waves-effect ml-0 btn-blue-grey btn-amber'
                >
                  <i className='fa fa-map-marker' aria-hidden='true'></i>
                </a>
                <p className='step-label'>{locationText}</p>
              </div>
              <div className='steps-step-2 active-step'>
                <a
                  href='#step-2'
                  className='btn btn-blue-grey btn-circle-2 waves-effect'
                >
                  <i className='fa fa-car' aria-hidden='true'></i>
                </a>
                <p className='step-label px-1'>{bookingDetail}</p>
              </div>
              <div className='steps-step-2'>
                <a
                  href='#step-3'
                  className='btn btn-blue-grey btn-circle-2 waves-effect'
                >
                  <i className='fa fa-clock-o' aria-hidden='true'></i>
                </a>
                <p className='step-label px-1'>{waitingText}</p>
              </div>
              <div className='steps-step-2'>
                <a
                  href='#step-4'
                  className='btn btn-blue-grey btn-circle-2 waves-effect mr-0'
                >
                  <i className='fa fa-check' aria-hidden='true'></i>
                </a>
                <p className='step-label'>{doneText}</p>
              </div>
            </div>
          </div>
          {price !== 0 && (
            <div className='price'>
              <h3 className='price-head'>
                <span className='price-symbol'>€</span>
                {price}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BookingHeader.propTypes = {
  currentStep: PropTypes.string.isRequired,
  price: PropTypes.number
}

BookingHeader.defaultProps = {
  currentStep: 'booking-details',
  price: 0
}

export default BookingHeader
